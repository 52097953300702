/**_common */
.btn {
    &.btn-border {
        border: 2px solid @primary-color;
    }
    &.btn-primary {
        color: #fff;
        background-color: @primary-color;
        border-color: darken(@primary-color,10%);
        &:hover {
            background-color: fade(@primary-color, 80%);
            border-color: @primary-color;
        }
    }
    &.btn-second {
        color: #fff;
        background-color: @second-color;
        border-color: @second-color;
        &:hover {
            background-color: fade(@second-color, 80%);
            border-color: @second-color;
        }
    } 
    &.btn-third {
        color: #fff;
        background-color: @third-color;
        border-color: @third-color;
        &:hover {
            background-color: fade(@third-color, 80%);
            border-color: @third-color;
        }
    }
}
.form-group {
    .clearfix;
}
.form-control {
    padding: 10px 6px;
    height: 30px;
    border-radius: 2px;
}
