/* slider */
.section-slider {
    position: relative; 
    overflow: hidden;
}

.slider {
    @dot-width: 30px;
    @dot-height: 2px;
    width: 100%;
    .item {
        .caption {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            max-width: 100%;
            font-family: @third-font;
            .btn.btn-second {
                font-size: 10px;
                    padding: 6px 20px;
                    font-size: 16px;
                    border-radius: 3px;
            }
            &,a {
                color: @primary-color;
            }
            .btn {
                &.btn-primary ,
                &.btn-second,
                &.btn-third {
                    color: #fff;
                }
            }
            h1,h2,h3,h4 {
                font-size: 22px;
                text-transform: uppercase;  
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 35px;
                // font-size: 16px;
                font-size: inherit;
            }
        }
    }
    .subcaption {
        font-size: smaller;
        margin-top: 8px;
        text-align: justify;
    }
    @media (max-width:@screen-xs ) {
        &>div::after {
            display: none;
        }
        .item {
            .caption {
                position: relative;
                transform: none;
                margin-bottom: 15px;
                color: #333;
                padding-bottom: 40px;
                h1,h2,h3,h4 {
                    font-size: 22px; 
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 15px;
                    font-size: 14px;
                }
            }
        }
    }
}

.slide-right { 
    background: #216075; /* Old browsers */
    background: -moz-linear-gradient(top, #216075 1%, #0a3461 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #216075 1%,#0a3461 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #216075 1%,#0a3461 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#216075', endColorstr='#0a3461',GradientType=0 ); /* IE6-9 */
}
@media (min-width: @screen-xs) {
    .owl-theme .owl-nav.disabled+.owl-dots {
        margin-top: -30px;
    }
    .slide-right {
        min-height: 50px;
        position: absolute;
        top: 0; 
        bottom: 0;
        right: 15px;
        left: auto;
        z-index: 1;
        height: 100%;
        padding-right: 0;
        padding-left: 0;
    }
}