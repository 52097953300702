// _top
.top {
	.clearfix; 
	background: @second-color url(../images/top_bg.jpg) no-repeat;
	border-bottom: 2px solid @primary-color;
	font-size: 12px;
}
.list-top {
	margin: 0;
	padding: 0;
	display: inline-block;
	>li {
		display: inline-block;
		>span,>a {
			display: inline-block;
			padding: 6px 12px;
			color: #fff;
		}
		i { display: inline-block; color: #fff; vertical-align: middle;}
	}
}