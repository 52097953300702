/* Backto top 
----------*/
@color-1 :  @primary-color;
a.backtotop {
	/* */
	opacity: 0;
	position:fixed; bottom:10px; right:10px; 
	height:30px;width:30px; 
	background-color:@color-1; color:#fff; border:2px solid @color-1;
	text-align:center; line-height:20px;padding-top:1px; border-radius:30px;
	z-index: 9999;
	&.is-visible {
		opacity: 1;
	}
	&.fade-out {
		opacity: 1;
	}
	.fa {
		padding-right:5px;
		padding-left: 5px;
	}
} 