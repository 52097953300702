// Breaking New
@colorLabel : #dfba50;
@bgLabel: transparent;
@itemColor: #dfba50;
.breaking-new-wrapper {
    display: inline-block;
}
.title-head {
    display: inline-block;
    padding: 6px 12px;
    text-transform: uppercase;
    color: @colorLabel;
    background: @bgLabel;
}
.breaking-new {
    margin: 0;
    padding: 0;
    list-style: none;
    li{
        display: inline-block;
        >a,>span {
            color: @itemColor;
            display: block;
            padding: 6px 12px;
            white-space: nowrap;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.breaking-new.owl-carousel {
    display: inline-block;
    max-width: 320px;
    vertical-align: middle;
    .owl-nav {
        position: absolute;
        top: 3px;
        right: 0;
    }
    .owl-prev, .owl-next {
        display: inline-block;
        padding: 0;
        background: #b5b5b521;
        color: @itemColor;
        width: 30px;
        height: 24px;
        text-align: center;
        line-height: 24px;
    }
}

@media (max-width:@screen-xs) {
    .title-head { 
        padding: 8px 20px; 
    }
    .breaking-new-wrapper {
        display: block;
        width: 100%;
    }
    .breaking-new.owl-carousel {
        display: inline-block; 
        width: ~"calc(100% - 90px)"; 
        max-width: 240px;
        float: right;
    }
}
