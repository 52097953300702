/* header */
header {
    background: fade(#fff, 90);
    box-shadow: @boxShadow;
    .logo {
        margin: 0;
        padding: 0;
        // margin: 15px 15px 25px 15px;
        display: inline-block;
        max-width: 205px;
        img {max-width: 100%;}
    }
    .slogan {
        font-weight: 600;
        padding: 15px;
        font-size: large;
        font-style: italic;
    }

    @media (max-width: @screen-xs) {
        .logo, .slogan { 
            margin: auto;
            display: block;
            text-align: center; 
        }
    }

    @media (max-width: @screen-md) {
        
    }

    @media (max-width: @screen-lg) {

    }
    @media (min-width: @screen-md) {
        &.isfixed {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            margin: auto;
            z-index: 9;
            background: #fff;
        }
    }
    
}

/* user header */
.user-header { 
    margin-top: 10px;
    float: left;
    a, i {
        // font-weight: bold;
        // color: @primary-color;
    }
    .btn {
        padding: 0px 12px;
        margin-right: 8px;
    }

    .language {
        margin-right: 8px;
        >span,>a {
            margin-right: 8px;
        }
    }
    .btn-text  {}
    i.fa,i.icon { 
        margin-right: 8px; 
        line-height: 30px;
        vertical-align: middle;
    }
    .hover-panel {
        padding: 15px;
        padding-bottom: 0;
        background: #fff;
        border:@border;
        box-shadow: @boxShadow;
        border-radius: @radius;
    }
    .separate {
        width: 20px;
        display: inline-block;
    }
    @media (max-width: @screen-xs) {
        .separate {
            width: 0px;
        }
    }

    @media (max-width: @screen-md) {
        // margin-bottom: 25px;
        .btn {
            padding: 0px 10px;
        }
        i.fa,i.icon,[class^='ion'] {margin: 0;}
        .btn-text  {
            display: none;
        }
    }

    @media (max-width: @screen-lg) {
        
    }
}

@media (max-width: @screen-md) {
    header {
        // padding-top: 50px;
        // padding-bottom: 25px;
        box-shadow: none;    
        background: rgba(255, 255, 255, 0.8);
        .logo {
            max-width: 210px;
            padding: 0px;
            // background: rgba(255, 255, 255, 0.9);
            border-radius: 4px;
            margin: 10px auto;
        }
    }
    .user-header {
        // margin-top: 0; 
        // position: absolute;
        // top: 5px;
        // right: 5px;
        background: rgb(225, 242, 255);
        padding: 15px;
        width: 100%;
        text-align: center;
        border-radius: 5px;
        margin-top: 0;
    }
}

.specialHotline {
    .iconPhoneLarg {
        background: transparent url(../images/iconhotline.png) no-repeat top left;
        width: 24px;
        height: 30px;
        display: block;
        float: left;
    }
    .phoneNumber { 
        font-size: 16px;
        font-weight: bold;
        margin-left: -8px;
        color: #6f5200;
        display: inline-block;
        transform: translateY(-5px);
    }
    .lastText {
        font-size: 12px;
        color: @second-color;
        font-weight:bold;
        margin-left: 5px;
        display: inline-block;
        transform: translateY(-5px);
    }
    .subline {
        display: block;
        font-size: 11px;
        white-space: nowrap;
        margin-top: -5px;
        letter-spacing: 1px;
    }
}