
/* ==========================================================================
   Navigation
   ========================================================================== */   
   .navigation {
    zoom: 1;
    display: inline-block;
    z-index: 99;
    position: relative;
    vertical-align: top;
  }
  .navigation .navigation_icon {
    display: none;
    width: 30px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
  }
  .navigation .navigation_icon:before,
  .navigation .navigation_icon:after {
    content: "  ";
  }
  .navigation .navigation_icon:before,
  .navigation .navigation_icon:after,
  .navigation .navigation_icon > span {
    display: block;
    width: 20px;
    height: 1px;
    background-color: @primary-color;
    margin: 5px auto;
  }
  .navigation:before,
  .navigation:after {
    content: " ";
    display: table;
  }
  .navigation:after {
    clear: both;
  }
  .navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .navigation a {
    text-decoration: none;
  }
  .navigation ul {
    text-align: center;
  }
  .navigation ul > li {
    display: inline-block;
  }
  .navigation ul > li > a,
  .navigation ul > li > span {
    display: block;
    padding: 5px 15px;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    display: block;
    padding: 8px 30px;
  }
  /* PC
  */
  @media (min-width: 768px) {
    .navigation ul > li .sub-icon {
      display: block;
      position: absolute;
      top: 48%;
      right: 6px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      width: 0;
      height: 0;
      text-indent: -999999px;
      border-top: 4px dashed #999;
      border-top: 4px solid\9;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
    .navigation ul > li > ul > li .sub-icon {
      display: none;
    }
    .navigation ul > li ul {
      position: absolute;
      margin-top: -2px;
      min-width: 180px;
      text-align: left;
      background: #fff;
      -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
              box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 0;
              border-radius: 0;
      display: block;
      visibility: hidden;
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
      -webkit-transition: opacity .4s ease, visibility .4s linear 0s, -webkit-transform .4s ease;
      transition: opacity .4s ease, visibility .4s linear 0s, -webkit-transform .4s ease;
      transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
      transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s, -webkit-transform .4s ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
      border: 0px solid transparent;
      border-top: 2px solid @primary-color;
      z-index: 1;
    }
    .navigation ul > li ul:before {
      content: " ";
      border-bottom: 4px dashed @primary-color;
      border-bottom: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      position: absolute;
      left: 20px;
      top: -6px;
    }
    .navigation ul > li ul > li {
      display: block;
      float: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;
    }
    .navigation ul > li ul > li > a:before {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      width: 0px;
      margin: auto;
      height: 2px;
      background: transparent;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
    }
    .navigation ul > li ul > li > a:hover:before,
    .navigation ul > li ul > li > a:focus:before {
      background: fade(#000, 10);
      width: 100%;
    }
    .navigation ul > li ul > li:last-child {
      border-bottom: none;
    }
    .navigation ul > li:hover > ul {
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
      visibility: inherit;
    }
  
    .navigation ul > li> ul > li ul {
        left:100%;
        top: 0;
        border-top:0;
        border-left: 0px solid #eaeaea;
    }
    .navigation ul > li> ul > li ul:before {
        transform: rotate(-90deg);
        left:-6px;
        top: 3px;
        border-color: transparent  transparent transparent transparent;
    }
  }
  /* ----------------
      Mobile
   ------------------*/
  @media (max-width: 768px) {
    .navigation .navigation_icon {
      display: block;
      position: fixed;
      left: 0px;
      top: 0px;
      background: transparent;
      z-index: 9999;
    }
    .navigation > ul {
      position: fixed;
      display: block;
      top: 0;
      left: -220px;
      bottom: 0;
      height: 100%;
      width: 220px;
      background: #fff;
      -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
              box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
              z-index: 9999;
    }
    .navigation > ul > li {
      float: none;
      display: block;
      text-align: left;
    }
    .navigation > ul > li > a,
    .navigation > ul > li > span {
      padding: 10px 30px;
      font-size: 14px;
      border-bottom: 1px solid #eaeaea;
    }
    
    .navigation > ul > li ul {
      text-align: left;
    }
    .navigation > ul > li ul > li {
      display: block;
    }
    .navigation > ul > li ul > li > a {
      padding-left: 30px;
      display: block;
      background: #e8e8e8;
      border-bottom: 1px solid #eaeaea;
    }
    .navigation .navigation_icon:before,
    .navigation .navigation_icon:after,
    .navigation .navigation_icon > span {
      -webkit-transition: all 0.6s ease;
      transition: all 0.6s ease;
    }
    .navigation .navigation_icon:before {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    .navigation .navigation_icon:after {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    .navigation .navigation_icon > span {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
      opacity: 1;
    }
    .navigation > ul,
    .navigation .navigation_icon {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .navigation.nav-open > ul {
      left: 0;
      z-index: 9999;
      overflow: auto;
    }
    .navigation.nav-open .navigation_icon {
      left: 220px;
      z-index: 9999;
    }
    .navigation.nav-open .navigation_icon:before {
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      margin-top: 10px;
    }
    .navigation.nav-open .navigation_icon:after {
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      margin-top: -12px;
    }
    .navigation.nav-open .navigation_icon > span {
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      opacity: 0;
    }
  }
  .backdrop_nav {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }
  @media (min-width: 768px) {
    .navigation ul > li .sub-icon {
      display: none;
    }
    li.hasSub > a {
      position: relative;
    }
    li.hasSub > a:after {
      content: "";
      position: relative;
      right: 0px;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 0;
      height: 0;
      display: inline-block;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
  @media (max-width: 768px) {
    .navigation > ul > li:first-child {
      border-top: 1px solid #eaeaea;
    }
    .navigation .hasSub {
      position: relative;
    }
    .navigation .hasSub > .sub-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      padding-top: 10px;
      padding-left: 17px;
      width: 40px;
      height: 37px;
      background: transparent;
      border-left: 1px solid #eaeaea;
      border-bottom: none;
    }
    .navigation .hasSub > ul {
      display: none;
    }
    .navigation .hasSub.hasSub_on > ul {
      display: block;
    }
    .navigation > ul > li {
      position: relative;
    }
    .navigation > ul > li > ul {
      display: none;
      padding: 0;
    }
    .navigation > ul > li > ul li > a {
      border-bottom: 1px solid #eaeaea;
      background-color: #fff;
    }
  }
  @media (max-width: 768px) { 
    .navi-wrapper .container {
        position: relative;
        z-index: 9;
      }
      .navigation>ul {    
        padding-top: 70px !important;
        background: #fff url(../images/logo-white.png) no-repeat center ~'20px / 45%'  auto;
      }
  }
  
  
  @media (min-width: 768px) {
    .navi-wrapper .container {
      position: relative;
      z-index: 9;
    }
    .navi-wrapper {
      margin-left: 25px;
    }
    .navigation > ul > li > a,
    .navigation > ul > li > span {
      padding: 15px 8px;
      letter-spacing: -.8px;
      // font-size: 14px;
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 0px;
        margin: auto;
        height: 2px;
        background: @primary-color;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
      }
    }
    .navigation > ul > li > a:hover,
    .navigation > ul > li > span:hover {
      &::before {width: 100%}
    }
      .navigation .mega-menu>ul {
          left: 0;
          max-width: 100%;
      min-height: 60px;
      }
      .navigation .mega-menu>ul:before {
          display: none;
      }
      .navigation .mega-menu>ul>li {
          display: inline-block;
          min-width: calc(20% - 3px);
      }
    .navigation .mega-menu>ul>li:last-child>a{
      border-bottom: 1px solid #527c9c;
    }
  }
  
  
  
  .navigation > ul > li  a,
  .navigation > ul > li  span {
    color: #666;
  }
  .navigation > ul > li  a:hover,
  .navigation > ul > li  span:hover {
    color: #f1f1f1;
    background: @primary-color;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    color: @second-color;
    text-transform: uppercase;
    
  }
  .navigation > ul > li > a:hover,
  .navigation > ul > li > span:hover {
    color: @primary-color;
    background: transparent;
  }
  
  @media (max-width: 1200px) {
    .navigation > ul > li > a,
    .navigation > ul > li > span {
      padding: 8px 4px;
      font-size: 14px;
    }
  }
  
  /* FIXED TOP */
  .top.isfixed .navigation {
    position: fixed;
    top:0;
    left: 0;
    right: 0; 
    background: #f5f5f5;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 2px 3px #0000001a;
  }




  .navi-wrapper .navigation {
    vertical-align: middle;
    >ul {      
      text-align: left;
    }
  }