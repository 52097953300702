.full-width {
    width: 100% !important;
}
.normal-control {
    padding: 15px 0px;
}
.checkbox-control {
    padding: 10px 0;
}
.icon-wrapper-raidus {
    border-radius: 100%;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
    &.danger {        
        background: #f00;
        color: #fff;
    }&.success {        
        background: @primary-color;
        color: #fff;
    }
}
.row-group {
    .clearfix;
}
#panel-game,
#panel-telco,
#panel-other {
    padding: 15px 0;
}
.btn-addrow{
    cursor: pointer;
    display: inline-block;
    padding: 5px 15px 0 15px;
    border-radius: 4px;
    background:  @primary-color;
    color: #fff;
    margin-bottom: 15px;
    .label {
        margin-top: 2px;
    }
}
.inline-control {
    display: inline-block;
    width: 30px;
}
.form-frontpage {
    select.form-control,
    .form-control.normal-control {
        min-width: auto;
    }
    &.form-sm {
        .telco-icon::before {
            background-size: 32px;
            width: 32px;
        }
        .normal-control {
            padding: 10px 0px;
        }
        .checkbox-control {
            padding: 5px 0;
        }
        .form-control {
            height: 32px;
            padding: 3px 0px 0 6px;
        }
    }
}