/* footer */
@f-color : #666;
footer {
    // background: @primary-color;
    a {
        color: @f-color
    }
    padding-top: 15px; 
    .logo-footer {
        display: inline-block;
        padding-top:30px;
    }
    .readmore{
        float: right;
        // color: #fff; 
    }
    .listFooter {
        // font-size: 13px;
        i, .fa,.icon {
            margin-right: 15px;
        }
        .title {
            // border-left: 2px solid @second-color;
            // padding-left: 10px;
            display: block;
            margin: 30px 0 15px;    font-size: 18px;
            &,a {
                color: @f-color;
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
    .contact-line {
        .clearfix;
        display: block;
        padding: 0 0 10px 0;
        // color: #f5f5f5;
        img {
            float: left;
            margin-top: 5px;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
    .info-pg {
        // color: #f5f5f5;
        padding: 15px 0;
    }
}
.social-inline {
    .btn-social {
        display: inline-block;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.btn-social {
    display: block;
    width: 30px;
    height: 30px;
    color: #fff;
    background: @second-color;
    text-align: center;
    line-height: 30px;
    border-radius: @radius;
    font-size: 18px;
    transition: all .3s ease;
    i.fa {
        margin-right:0;
    }
    &:hover { 
        // background: #fff;
        color:@second-color
    }
}

.copyright {
    padding: 15px;
    border-top: 1px solid  fade(#fff,10);
    &,a {
        // color: #cccccc;
        font-size: smaller;;
    }
}
.copyblock-right {
    float: right;        
}
.company-section {
    // background: #f5f5f5;
    padding: 15px 0;
    color: @f-color;
    .logo-footer {
        margin-top: 20px;
        display: block;
    }
}
@media (max-width: @screen-xs) {
    footer, .footer {
        // background: @second-color;
    }
    .copyright {
        text-align: center;
    }
    .copyblock-right {
        float: none;   
        display: block;
        padding: 6px 0;     
    }
}