@color-main: @primary-color;
@_color: #26ad86;
.toggleAreaClass {
	display: none;
	&.active {
		display: block;
	}
	.clearfix;
	&.order-exported {	
		background: #f5f5f5;	
		font-size: inherit;
		padding: 15px 0px;
	}
}
.list-banks-detail .item{
	padding: 15px;
	background: #f7f7f7;
	margin: 15px 0;
}
.list-banks {
	.clearfix;
	padding:0;
	margin: 0;
	margin-bottom: 15px;
	&>li,&>div,&>span,&>a {
		display: block;
		width: 20%;
		float: left;
		padding: 5px;
		&>a,&>span {
			&:after {
				content: ' ';
				display: block;
				position: absolute;
				left: 0;top:0;
				width: 100%;
				height: 100%;
				z-index: 2;
				transition: all 0.3s ease;
			}
			&:before {
				    content: "\f046";
				    color: @color-main;
				    display: inline-block;
				    width: 20px;
				    height: 20px;
				    position: absolute;
				    top: -28px;
				    right: -28px;
				    opacity: 0;
				    background: #fff;
				    font: normal normal normal 14px/1 FontAwesome;
				    font-size: 20px;
				    text-rendering: auto;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				    z-index: 3;
				    transition: all 0.3s ease;
			}
		}
		&.open,&:hover,&:focus,&:active {
			&>a,&>span { 
				border: 1px solid @color-main;				
				box-sizing: border-box;
				&:after {
					border: 1px solid @color-main;
				}
				&:before {
					top: -8px;
				    right: -8px;
				    opacity: 1;
				}
			}
		}
		&>a,&>span {
			border: 1px solid #eaeaea;
			display: block;
			position: relative;
			padding-bottom: 50%;
			img {
				position: absolute;
				left: 0; top:0;right: 0;bottom: 0;
				height: 100%;
				width: 100%;
			}
		}
	}
}

.list-banks-detail {
	line-height: 1.4;
	.img-wraper {
	    display: block;
	    max-width: 290px;
	    margin: auto;
	}
	.title {
		font-size: inherit;
		color: @color-main;
		margin-bottom: 15px;
	}
	.item {
		.img{
			width: 100%;
			display: block;
			position: relative;
			padding-bottom: 50%;
			border: 2px solid @_color;
			img {
				position: absolute;
				left: 0; top:0;right: 0;bottom: 0;
				height: 100%;
				width: 100%;
			}
		}
	}
}