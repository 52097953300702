.section-slider {
    .cover {
        background: #000;   
        img {
            opacity: .35;
        }
        .heading {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            text-align: center;
            transform: translateY(-50%);
            &,a {                
                color: #fff;
            }
            .h1, .h2, .h3, h1, h2, h3 {
                margin-top: 10px;
                margin-bottom: 10px;
                &.dboubeLine {
                    text-transform: uppercase;
                    font-weight: 400;
                    // border:1px solid #fff;
                    border-width: 1px 0 1px 0;
                    display: inline-block;
                    padding: 10px 30px 45px;
                    position: relative;
                    &::before {
                        content: " ";
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 35px;
                        background: transparent url(../images/icon-logo.png) no-repeat center bottom;
                    }
                    //icon-contact-page.png
                    &.contact-icon {
                        &::before {
                            background-image: url(../images/icon-contact-page.png)
                        }
                    }
                }
            }
        }
    }
}

.fullImage {
    margin-bottom: 15px;
    img {
        margin: auto;
        display: block;
        width: 100%;
    }
}
.hotline {
    font-size: 28px;
    font-weight: bold;
}
.box-shadow {    
    box-shadow: @boxShadow;
}
.border-left {    
    border-left: 1px solid #eaeaea;
}
.sidebar {
    // margin-top: 15px;
    padding: 0 15px;
    background: #fff;
    .blockTitle { 
        &.lineHorizontal {
            position: relative;
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                top:50%;
                left: 0;
                width: ~'calc(100% - 15px)';
                height: 1px;
                // background: @second-color;
                background: #eaeaea;
            }
            h1, h2, h3 { 
                position: relative;
            }
        }       
        h1, h2, h3 {
            margin-top: 15px;
            position: relative;
            background: #fff;
            display: inline-block;
            padding: 0px 15px;
            border-left: 0px solid fade(@second-color, 20);            
        }
    }
    .blockContent {
        padding: 15px;
    }
    &.second-color {        
        background: @second-color;
        color: #fff;
        .blockTitle.lineHorizontal {
            &:before {
                background: #fff;
            }
        }
        h1, h2, h3 {
            background: @second-color;
            border-left-color: fade(#fff, 20); 
            &, span, a {
                color: #fff;
            }
        }
        .hotline {
            color:#FFEB3B;
        }
    }
    .blockTitle.gray-color {        
        background: #f5f5f5;
        color: #333;
        &.lineHorizontal {
            &:before {
                background: #333;
            }
        }
        h1, h2, h3 {
            background: #f5f5f5;
            border-left-color: fade(#333, 20); 
            &, span, a {
                color: #333;
            }
        } 
    }
}

.list-second-color {
    list-style: none;
    padding: 0;
    margin: 0;
    >li {
        display: block;
        // border: 1px solid #eaeaea;
        // border-bottom-width: 0;
        // &:last-child {
        //     border-bottom-width: 1px;
        // }
        // margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0px;
        }
        >a,>span {
            display: block;
            // background: #f5f5f5;
            color: #333;
            // border: 1px solid #fff; 
            padding: 6px 0px;
            transition: @transition;
        }
        // &.active, &:hover {
        //     >a,>span {
        //         background: @second-color;
        //         text-decoration: none;
        //         color: #fff;
        //         padding-left: 20px;
        //     }
        // }
    }
}
.detailContent {
    // padding: 15px;
    // background: #fff;
    &.blogItem {
        border:none;
    }
}
.relativeNews {
    padding-left: 24px;
    li>a {
        padding: 4px 0px;
        display: inline-block;
        color:#333;
        &:hover {
            color: @second-color;
        }
    }
}

.addressAccordion {
    background: #fff;
    position: relative;
    padding-top: 50px;
    min-height: 193px;
    i, .fa,.icon {
        margin-right: 15px;
    }
    .heading {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        color: #999;
        &.active-colapsed {
            color: @second-color;
        }
    }
    .info-pg:first-child .heading {
        left: 15px;
        right: 50%;
    }
    .info-pg:last-child .heading {
        right:  15px;
        left: 50%;
        text-align: right;
    }
}

.heading-page {
    background: #f7f5f2;
}
.breadcrumb {
    padding: 8px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    a, span {
        display: inline-block;
        margin-right: 5px;
        color: #999;
    }
    a {        
        font-weight: 500;
        color: @primary-color;
    }
}
.page-title {
    font-weight: 700;
    color: #666;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
}
.mainpage-wrapper {
    padding-top: 20px;
}