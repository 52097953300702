/* main */
body {
	font-family: @primary-font;
	// background: #fff url(../images/bg-footer.png) no-repeat center bottom;
	.homepage {
		background: transparent url(../images/hg-home.jpg) no-repeat center top;
		background-size: 100% auto;
	}
	font-size: 14px;	
	line-height: 1.6;
}
.container {
	max-width: 1024px;
}
.main .section:last-child {
	>.container>div {
		border-radius: 0 0 4px 4px ;
		margin-bottom: 30px;
	}
}
.bg-lightblue {
	background: #edf9f8;
}
.bg-shadowimg {
	background: transparent url(../images/bottom-slide.png) no-repeat center 45%;
    background-size: 100%;
    padding-bottom: 32px;
}
.bg-green {
	background: #3f998f;
}
.readmore {
	color: @primary-color;
	font-size: 11px;
	font-style: italic;
}
.text-red {
	color: #900 !important;
}
.text-third-color {
	color: @third-color !important;
}
@media (max-width: 1400px){
	body .homepage{
		background-size: 100% auto;
	}
}
@media (max-width: 568px){
	body .homepage{
		background-size: 100% auto;
	}
}
.section, section { 
	// padding-bottom: 15px;
	&.section-gray {
		background: #f5f5f5; 
	}
}


/* listServices */
.listServices { 
	&,a {
		color: #f5f5f5;
	}
	.item {
		padding: 15px;
		border-bottom: 1px solid #467775;
		&:hover {
			background: @primary-color;
		}
		>span {
			vertical-align: middle;
		}
		.icon-strip {
			margin-right: 4px;
		}
		.text-title{
			padding: 15px 5px;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 11px;
		}
	}
}
.icon-strip {
	background-color: transparent;
	background-image: url(../images/icon-strip.png);
	background-repeat: no-repeat;
	background-position: 0 0;
	display: inline-block;
	width: 30px;
	height: 30px;
	&.strip-heart {
		background-position: -6px -5px;
	}
	&.strip-college {
		background-position: -6px -67px;
	}
	&.strip-car {
		background-position: -6px -125px;
	}
	&.strip-global-hand {
		background-position: -6px -185px;
	}
	&.strip-house-hand {
		background-position: -6px -245px;
	} 
}

/** sliderShowCase */
.sliderShowCase {
	background: @second-color ;
	
	background: #155588; /* Old browsers */
	background: -moz-linear-gradient(top, #155588 0%, #246fbf 0%, #12416a 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #155588 0%,#246fbf 0%,#12416a 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #155588 0%,#246fbf 0%,#12416a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#155588', endColorstr='#12416a',GradientType=0 ); /* IE6-9 */
	.owl-theme .owl-nav {
		margin-top: 0px;
	}
	.hover{
		opacity: 0;
		position: absolute;
		top:0;left: 0; bottom: 0; right: 0;
		background: #edf9f8;
	}
	a:hover {		
		text-decoration: none;
	}
	.item {
		position: relative;
		text-align: center;
		display: block;
		.title , a{
			color: #c9b489;
			text-transform: uppercase; 
		}
		.title {
			padding: 15px; 
			margin-top: -30px;   
			position: relative;
			z-index: 1;
		}
		&:hover {
			.hover{
				opacity: 1;
			}
			.title, a {
				color: @primary-color;
			}
		}
	}
	@media screen and (min-width: @screen-xs) {
		.owl-nav .owl-next, .owl-nav .owl-prev{
			width: 30px;
			height: 73px;
			position: absolute;
			top: 50%; 
			border-radius: 0;
			margin: 0;
			background: transparent url(../images/nav-slide.png) no-repeat center top;
			i {
				font-size: 34px;
				line-height: 60px;
			}
			
		}
		.owl-stage-outer {
			z-index: 9;
		}
		.owl-nav .owl-prev { 
			left: 0;
			transform: translate(-30px , -50%); 
			background-position: top left;
			&:hover {
				background: transparent url(../images/nav-slide.png) no-repeat top left;
			}
		}
		.owl-nav .owl-next {
			right: 0;
			transform: translate(30px , -50%);		
			background-position: top right;
			&:hover {
				background: transparent url(../images/nav-slide.png) no-repeat top right;
			}
		}
	}
	
}

.section-banner {
	position: relative; 
}

.slider-banner {
	position: relative;
	z-index: 2;
	.caption {padding: 15px 0;}
	@media screen and (min-width:@screen-xs) {
		.caption {
			padding: 15px;
			background: fade(#000,40);
			position: absolute;
			bottom: 0;
			top:auto;
			right: 0;
			left: 0;
			&,a{
				color: #fff;
			}
		}
	}
	
	.h3, h3{
		margin: 0;
		margin-bottom: 10px;
		font-weight: normal;
		font-size: 20px;
	}
	.owl-theme .owl-nav.disabled+.owl-dots {
		display: inline-block;
		float: right;    
		margin-top: -30px;
		z-index: 1;
		position: relative;
	}
}

.form-wrapper{
	padding: 15px 0;
	@media screen and (min-width:@screen-xs){
		padding-left: 15px;
	}
}
.darkform {
	.form-group {
		margin-bottom: 15px;
	}
	textarea.form-control {
		height: auto;
		min-height: 100px;
	}
	.form-group .btn_submit_action {
		margin-top: 0;
		text-transform: uppercase; 
		text-transform: uppercase;
		background: #ffffff;
		color:  @second-color;
		font-weight: 500;
		float: right;
		&:hover {
			background: @second-color;
			color: #fff;
		}
	}
	.form-control {
		background: transparent;
		border-radius: 0;
		border: 2px solid #fff;
		line-height: 18px;
		min-height: 35px;
		color: #fff;
	}
}
@media (max-width:@screen-xs) {
	.block-section.slide-right {
		padding:  0;
	}
}

.post-dark-bg { 
	.blockCase {
		padding: 15px 15px 30px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.13);
		.cover {
			max-width: 50%;
		}
		h1, h2, h3, h4{
			a{
				color: #f5f5f5;
				font-size: 13px;
			}
		}
		.content{
			color: #f5f5f5;
			font-size: 12px;
		}
		.readmore {
			color: #f5f5f5;
		}
	}
	&:last-child  .blockCase{
		border-bottom: none;
	}
}

.faqblock  {
	.blockCase {
		padding-bottom: 15px;
		line-height: 1.4;
	}
	.blockTitle {
		margin-bottom: 15px;
	}
	.post-dark-bg .blockCase{
		border:1px solid  rgba(255, 255, 255, 0.3);
		border-radius: 5px;
	}
	.heading {
		h1,h2,h3,h4 {
			margin-bottom: 0;
		}
		a {
			display: block;
			padding: 10px 0;
			cursor: pointer;
		}
		position: relative;
		padding-left: 15px; 
		&:before,&:after {
			content: " ";
			position: absolute;
			left: 0;
			top:50%;
			display: block;
			width: 10px;
			height: 2px;
			background: #f5f5f5;
			margin-top:-1px;
			transition: all .3s ease;
			transform: rotate(0deg);
		}
		&.active-colapsed {
			&::before{
				transform: rotate(-180deg);
			}
			&::after{
				transform: rotate(90deg);
			}
		}
	}
	.content {
		margin: 10px 0;
		padding-left: 15px;
	}
}


.newsblock, .faqblock  {
	.blockTitle h3 {
		text-align: center;
		margin-bottom: 0;
		margin-top: 15px;
	}
}

.fullImage .blockCase .cover{
	width: 100%;
	max-width: 100%;
	margin-bottom: 15px;
}

 .tag-item {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #eaeaea;
    background: #e8e8e8;
    border-radius: 4px;
	margin-right: 5px;
	font-size: smaller;
	margin-bottom: 15px;    color: #333;
}

.cover-caption {
	text-align: center;
    font-size: smaller;
    font-style: italic;
    padding: 6px 12px;
    color: #999;
}
.blockPage {
	.blockTitle {
		h1{
			text-transform: none;
			font-size: 18px;
			border-bottom: 1px solid #eaeaea;
			margin-bottom: 10px;
		}
		h2 , .sapo{
			text-transform: none;
			font-size: inherit;
			padding-top: 0;
			line-height: 1.4;
		}
		hr {
			margin-top: 5px;
			margin-bottom: 5px;
		}
		.info-meta {
			font-size: 11px;
			color: #999;
			margin-bottom: 10px;
			.info-inline {
				display: inline-block;
				margin-right: 10px;
				i {
					margin-right: 5px;
				}
			}
		}
	}
}
