.blockDichVu {  
    @media (min-width:@screen-md) {
        &.mainColumn, &.sideColumn {
            float: left;
        }
        &.mainColumn {
            width: 60%;
            margin-right: 15px;
        }
        &.sideColumn {
            width: calc(~'40%' - 15px); 
        }
    }
}
// .blockDichVu {    
//     .block-header {
//         text-align: center;
//         background-color: @primary-color;
//         color:#f5f5f5;
//         text-transform: uppercase;
//         >span {
//             display: block;
//             padding: 10px 20px;
//             font-weight: bold;
//         }
//     }
// }
.blockTitle {
    h1,h2,h3 {
        text-align: left;
        margin-top: 0;
        padding-top: 15px;
        margin-bottom: 15px;
        // padding-left: 15px;  
        // border-left: 2px solid @second-color;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 30px;
        vertical-align: middle;
        span, a {
            color: @primary-color;
            font-weight: 600;
        }
        
        .subTitle {
            font-size: 14px;
            color:#333;
        }
    }
    &.text-center{
        h2,h2,h3 {
            span, a {
                color: #9E9E9E;
                font-weight: 300;
                text-align: center;
                display: block;
            }
        }
    }
}
.darkBlock {
    .blockTitle {
        h1,h2,h3 {
            span, a {
                color: #f5f5f5;
            }
        }
    }
}
 
.blockCase {
    .clearfix;
    // padding: 5px 15px 30px;
    margin-bottom: 15px;
    // background: #f5f5f5;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    img {
        max-width: 100%;
    }
    .cover {
        max-width: 44%;
        float: left;
        margin-right: 15px;
    }
    // &.active, &:hover {
    //     background: #ede9ea;
    // }
    // &:before {
    //     content: ' ';
    //     position: absolute;
    //     top:0;
    //     left: 0;
    //     height: 33.333333%;
    //     width: 2px;
    //     background: fade(@second-color, 50%);
    // }
    h1,h2,h3,h4 {
        margin-top: 0;
        font-weight: 600;
        // text-transform: uppercase;
        font-size: 15px;
        a {
            color: #5a5a5a;
        }
    }
    p {
        margin-bottom: 20px;
        font-weight: 600; 
        // text-transform: uppercase;  
    }
    .content {
        color: #666;
    }
    .readmore {
        float: right;
    }
    
    @media (min-width: @screen-xs) {
        h1,h2,h3,h4 {
            font-size: 15px; 
            margin-bottom: 10px;
            color: @primary-color;
        }
        p { 
            font-weight: 600; 
            text-transform: uppercase;
            font-size: 16px; 
        }
    }
}

.small-blockitem {
    h1,h2,h3,h4 {
        font-size: 13px;
        color: #333;
        font-weight: normal;
    }
} 

.boxTable {
    background: #f5f5f5;
    border: 1px solid #e8e8e8;
    .heading { 
        h1,h2,h3,h4 {
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
            padding: 15px;
            font-size: 14px;
            text-align: center;
            background: #f5f5f5;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        }
    }
    .content {
        .title {
            font-size: 14px;
        }
        min-height: 120px;
        padding: 15px;
    }
}
.table-course {
    th,td {
        text-align: center
    }
}
.titleList {
    padding-left: 15px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        border-radius: 100%;
        background: @second-color;    
        left: 0;
        top: 4px;
    }
}

// ul.listTainguyen {
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     >li {
//         >div {
//             float: left;
//             >span {
//                 display: block;
//                 padding: 4px 15px;
//             }
//         }
//         >div:nth-child(1) {        
//             width: 60%;
//         }
//         >div:nth-child(2) {        
//             width: 40%;
//         }
//     }
//     .imgIcon {
//         max-height: 24px;
//         vertical-align: middle;
//         margin-right: 5px;
//     }
// }
// .listGdganday {
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     >li {
//         .clearfix;
//         &.list-head {
//             background-color: #f6f6f6 !important;
//             >div>span {
//                 font-weight: bold;
//                 padding: 10px 5px;
//             }
//         }
//         >div {
//             float: left;
//             >span {
//                 display: block;
//                 padding: 7px 5px;
//                 border-right: 1px solid #eaeaea;
//                 border-bottom: 1px solid #eaeaea;
//             }
//         }
//         >div:last-child {
//             >span {
//                 border-right: none;
//             }
//         }
//         >div:nth-child(1) {        
//             width: 20%;
//         }
//         >div:nth-child(2) {        
//             width: 30%;
//         }
//         >div:nth-child(3) {        
//             width: 25%;
//         }
//         >div:nth-child(4) {        
//             width: 25%;
//         }

//         &:nth-child(odd) {
//             background: #fbc1c2;
//         }
        
//         &:nth-child(even) {
//             background: #aefeff;
//         }
//     }
// }
// ul.listTygia {
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     >li {
//         .clearfix;
//         &.list-head {
//             background-color: #f6f6f6 !important;
//             >div>span {
//                 font-weight: bold;
//             }
//         }
//         >div {
//             float: left; 
//         }
        
//         >div:nth-child(1) {        
//             width: 33.333333%; 
//             border: 1px solid #eaeaea;
//             border-width: 0 1px 1px 0 ;
//             >span {
//                 display: block;
//                 padding: 30px 0;
//                 white-space: nowrap;
//             }
//         }
//         >div:nth-child(2) {        
//             width: 66.666666%;
//             border-right: none;
//             // border-bottom: 1px solid #eaeaea;
//             >div {
//                 span {
//                     display: block;
//                     padding: 9.725px 0px;
//                     border-bottom: 1px solid #eaeaea;
//                     border-right: 1px solid #eaeaea; 
//                     white-space: nowrap;
//                 } 
//                 &:nth-child(even) {
//                     // background: #f00;
//                     span {
//                         border-right: none  ;
//                     }
//                 }
//             } 
//         }
//     }
// }
.boxContent {
    padding: 0px 0;
    .btn_submit_action{
        margin-top: 15px;
        margin-bottom: 0;
    }
}

// FORM
.form-label {
    padding: 7.25px 0px;
    display: block;
    display: block;
    font-size: 14px;
    white-space: nowrap;
}
#khoahocSelect,#khoahocSelect .dd-select{
    width: 100% !important;
}
.dd-select {
    .dd-selected {
        padding: 0px 10px !important;
        background: #fff;
        box-shadow: inset 0 1px 2px fade(#000,10%);
    }
    .dd-option-image, .dd-selected-image { 
        max-width: 40px !important;
    }
    .dd-selected-text {
        line-height: 30px !important;
        margin-bottom: 0;
    }    
}
.dd-options {
    width: 100% !important;
    .dd-option-text {
        line-height: 30px !important;
    }
}
.supPal {
    .h3 {
       font-size: 14px;
       text-transform: uppercase 
    }
    .text-block {
        &.normal-text {
            &,a,span {
                color: #333;
                font-size: 14px;
            }
            .fa,.icon, i {
                color: @second-color;
            }
        }
        &.color-text{
            &,a {
                color: @second-color;
                font-size: 16px;
            }
        }
        &.hotline {
            &,a {    
                font-size: 22px;
                font-weight: 700;
                letter-spacing: -1px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            }
        }
        >span,>a {
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
            margin:0 10px 10px 0px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    @media (min-width: @screen-xs) {
        padding: 15px 0 206px
    }
}

.blogItem {
    // padding: 15px;
    img {
        max-width: 100%;
        display: block;
        margin: auto;
    }
    & {
        border: 1px solid #e8e8e8;
        margin-bottom: 15px;
    }
    .title {
        padding: 10px 0;
        display:block;
        color:#333;
        font-weight: 500;
    }
    .info-meta {
        font-size: 11px;
        color: #999;
        margin-bottom: 10px;
        .info-inline {
            margin-right: 10px;
            i {
                margin-right: 5px;
            }
        }
    }

    .detail {
        .clearfix;
        padding:10px; 
        background: #fff;
        position: relative;
        transition: all .3s ease;
    }
    .btn.btn-viewmore {
        background: #ccc;
        border-radius: 0;
        // width: 100%;
        display: inline-block;
        margin: 0;
        padding: 2px 8px; 
        height: auto;
        overflow: hidden;
        transition: all .3s ease;
    }
    &.normalCat {
        .cover {
            display: block;
            min-width: 160px;
            width: 38%;
            float: left;
            margin: 20px 15px;
            position: relative;
            z-index: 1;
            border: 1px solid #fff;
            // box-shadow: @boxShadow;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            img {
                width: 100%;
                display: block;
            }
        }
        .btn.btn-viewmore {
            font-size: 11px;
            color: @second-color; 
            background: rgba(183, 183, 183, 0.2);
            border-radius: @radius;
            &:hover {
                background: @second-color;
                color: #fff;
            }
        }
    }
    &.aniReadmore {
        .title {            
            text-transform: uppercase;
        }
        .btn.btn-viewmore {
            height: 0;
            display: block;
            margin: 0 -10px -10px;
            padding: 0;
            text-transform: uppercase;
        }
        .cover {
            display: block;
            margin-bottom: 0;
            transition: all .3s ease;
        }
        &:hover {
            .cover {
                margin-bottom: -30px;
            }
            .btn.btn-viewmore {
                height: 30px;
                padding: 6px 10px;
            }
        }
    }
}