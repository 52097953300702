@colorText: #dfba50;
.search-form {
    position: relative; 
    width: 220px;
    float: right;
    .screen-reader-text {
        display:none;
    }
    label {
        display: block;
    }
    input {        
        width: 100%;
    }
    .search-field {
        border-radius: 2px;
        border: none;
        box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.15);
        padding: 2px 12px;
        line-height: 18px;
        margin-top: 4px;
        background: rgba(255, 255, 255, 0.4);
        font-weight: normal;
        color: @colorText;
        &::placeholder {
            color: @colorText;
            font-weight: normal;
        }
    }
    .search-submit {
        border: none;
        padding: 3px 12px;
        position: absolute;
        top: 3px;
        right: 0;    
        width: 30px;
        text-indent: -99999px;
        background: transparent url(../images/search-submit.png) no-repeat center;
    }
}

@media(max-width:@screen-xs) {
    .search-form {
        max-width: unset;
        width: 100%;
    }
}